import React from 'react';
import { BsLinkedin, BsTwitter } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';


const SocialMedia = () => {
  return (
    <div className="app__social">
        <div>
            <a href='https://www.linkedin.com/in/ray-mark-guevarra-7898b3b7/'>
              <BsLinkedin />
            </a>
        </div>
        <div>
            <a href='https://www.facebook.com/raymark.guevarra.3/about_overview/'>
              <FaFacebookF/>
            </a>
        </div>
        <div>
            <a href='https://twitter.com/CryptoMarkG1'>
              <BsTwitter/>
            </a>
        </div>
    </div>
  )
}

export default SocialMedia